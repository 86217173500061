import React, { useEffect, useState } from 'react';
import { DataSourceApi, PanelProps } from '@grafana/data';
import {SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView, getDataSourceSrv } from '@grafana/runtime';
import { TimeRangeContext } from './TimeRangeContext';
import * as dat from 'lil-gui';
import { LocationData } from './utils';
import AppNew from './AppNew';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

type Field = {
  name: string;
  type: string;
  values: any[]; // Replace 'any' with a more specific type if possible
};

export const SimplePanel: React.FC<Props> = ({options, data, width, height, fieldConfig, id }) => {

  const styles = useStyles2(getStyles);
  const [groundData, setGroundData] = useState<Field[]>([]);
  const [scData, setScData] = useState<LocationData>({ Azimuth: 0, Elevation: 0 });

  const exampleFields: Field[] = [
    { name: "", type: "", values: []}
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;
  const influxCuurentDelay = data.series[1] ? data.series[1].fields : exampleFields;

  const oneWayLightTimeField = influxCuurentDelay.find(field => field.name === "One-way Light Time [s]");

  const oneWayLightTimeFieldValues = oneWayLightTimeField && oneWayLightTimeField.values ? oneWayLightTimeField.values[0] : 0;
  
  //console.log("influxCuurentDaly", oneWayLightTimeField);

  const sqlQuery = data.series[0] && data.series[0].meta ? data.series[0].meta?.executedQueryString : "";

  let source = "";

  if (sqlQuery) {
    const regex = new RegExp(`facility\\s*=\\s*'(.*?)'`, 'i');
    const match = regex.exec(sqlQuery);
    // Use 'match' here. Remember that 'match' could be null if no match is found.

    if (match) {
      source = match[1]
    }
    
  }

  const[guiValues, setGuiValues] = useState({
    offSetX: 0,
    aggTime: '1s',
    aggFn: { fn: 'last' },
    startDateTime: data.timeRange.from.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'),
    endDateTime: data.timeRange.to.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'),
    screenSpacePanning: false,
    Agg: false,
    Last: false,
    lastCount: 50,
  });

  // lil-gui configuration
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guiContainerRef.current) {
      const gui = new dat.GUI({ container: guiContainerRef.current });
      gui.close();
  
      //gui.add(guiValues, 'screenSpacePanning').name('Space Panning');
  
      const f1 = gui.addFolder('Query');
     //f1.add(guiValues, 'startDateTime').name('Start Date Time').listen();
      //f1.add(guiValues, 'endDateTime').name('End Date Time').listen();
  
      const aggController = f1.add(guiValues, 'Agg').name('Agg');
      const aggTimeController = f1.add(guiValues, 'aggTime').name('Agg Time');
      const aggFnController = f1.add(guiValues.aggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      aggFnController.disable();
      aggTimeController.disable();
  
      const lastController = f1.add(guiValues, 'Last').name('Last');
      const lastCountController = f1.add(guiValues, 'lastCount', 1, 1000, 1).name('Last Count');
      lastCountController.disable();
  
      aggController.onChange((value) => {
        if (value) {
          aggTimeController.enable();
          aggFnController.enable();
        } else {
          aggTimeController.disable();
          aggFnController.disable();
        }
        //setGuiValues(prevValues => ({ ...prevValues, Agg: value }));
      });
  
      lastController.onChange((value) => {
        if (value) {
          lastCountController.enable();
        } else {
          lastCountController.disable();
        }
        //setGuiValues(prevValues => ({ ...prevValues, Last: value }));
      });
      
    }
  }, [guiValues]);

    // Extract the time range
    const timeRange = {
      from: data.timeRange.from,
      to: data.timeRange.to
    };

    let diffSouceName = "";
    if (source.toLowerCase().includes("escb".toLowerCase())) {
      diffSouceName = "ESCB";
    } else if (source.toLowerCase().includes("escg".toLowerCase())) {
      diffSouceName = "ESCG";
    }
  
    const fetchData = async (query: string, dataSourceName: string): Promise<Field[]> => {
      try {
        const dataSource = await getDataSourceSrv().get(dataSourceName);
        //@ts-ignore
        const result = await dataSource.query({targets: [{ refId: 'queryB', rawSql: query, format: 'table' }]}).toPromise();
        return result.data.length > 0 ? result.data[0].fields : [];
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };
  
    const fetchInfluxDB = async (query: string, dataSourceName: string) => {
      try {
        const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);
        //@ts-ignore
        const result = await dataSource.query({ targets: [{ query, refId: 'comQ' }] }).toPromise();
        return result.data;
      } catch (error) {
        console.error('Error fetching InfluxDB data:', error);
        return [];
      }
    };
  
    useEffect(() => {
      const exQuery = `SELECT station, scName, tAos, tLos, trCode 
        FROM bdps.operational_schedule 
        WHERE scName LIKE '${diffSouceName}' AND tLos > UNIX_TIMESTAMP()
        ORDER BY tLos ASC 
        LIMIT 1;`;
  
      const influxQuery1 = `from(bucket: "fd_30_days")
      |> range(start: -15d)
      |> filter(fn: (r) => r._measurement == "ESCAPADE")
      |> filter(fn: (r) => r.Spacecraft == "${diffSouceName}")
      |> filter(fn: (r) => r.Station == "DSS 34")
      |> last()`;
  
      fetchData(exQuery, 'MySQL - BTAPS - CVT').then(result => {
        setGroundData(result);
      });
  
      fetchInfluxDB(influxQuery1, 'INFLUX').then(result => {

        //console.log('resulasfast', result);
        const azColumn = result.filter(litem => litem.fields[1]?.name === "Azimuth [deg]");
        const elColumn = result.filter(litem => litem.fields[1]?.name === "Elevation [deg]");
        
        const azValue = azColumn[0]?.fields[1].values[0]?.toFixed(2) || 0;
        const elValue = elColumn[0]?.fields[1].values[0]?.toFixed(2) || 0;
  
        const locationDict: LocationData = { Azimuth: parseFloat(azValue), Elevation: parseFloat(elValue) };
  
        setScData(locationDict);
      });
    }, [diffSouceName]); // Effect will re-run if diffSouceName changes

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;

        .lil-gui.root{
          --background-color: #d29d00;
          --font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
        }
      `
    )}>
      <TimeRangeContext.Provider value={{ timeRange, guiValues, oneWayLightTimeFieldValues }}>
            <div ref={guiContainerRef} />
 
            {/* <App dbData={selectedData} width={width} height={height} source={source} options={options} groundData={groundData} locationData={scData}></App> */}
            <AppNew dbData={selectedData} width={width} height={height} source={source} options={options} groundData={groundData} locationData={scData}></AppNew>

      </TimeRangeContext.Provider>

    </div>
   ); 
};
