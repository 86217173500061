import React, { useState, useEffect } from "react";
import { css, cx } from '@emotion/css';
import { TLM } from "./utils";
import { useTimeRangeContext } from './TimeRangeContext';

interface VModeBoxProp {
  data: {
    name: string;
    VMode: TLM;
  };
}

const containerStyle = css`
  border: 6px solid gray;
  background-color: rgb(38, 38, 38);
  width: 3250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 40px;
  padding: 10px;
`;

const innerContainerStyle = css`
  display: flex;
  flex-direction: row;
`;

const innerPValueStyle = css`
  margin: 0px 50px;
`;

const VModeBox: React.FC<VModeBoxProp> = ({ data }) => {

  const {oneWayLightTimeFieldValues} = useTimeRangeContext();


  const [currentTime, setCurrentTime] = useState<string>("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      const year = now.getUTCFullYear();
      const start = new Date(Date.UTC(year, 0, 0));
      const diff = now.getTime() - start.getTime();
      const oneDay = 1000 * 60 * 60 * 24;
      const dayOfYear = Math.floor(diff / oneDay);

      const hours = String(now.getUTCHours()).padStart(2, '0');
      const minutes = String(now.getUTCMinutes()).padStart(2, '0');
      const seconds = String(now.getUTCSeconds()).padStart(2, '0');

      const formattedTime = `${year}/${String(dayOfYear).padStart(3, '0')} ${hours}:${minutes}:${seconds} Z`;
      setCurrentTime(formattedTime);
    };

    updateTime(); // Set initial time
    const timerId = setInterval(updateTime, 1000); // Update time every second

    return () => clearInterval(timerId); // Clean up the timer when component unmounts
  }, []);

  // Conditional styling for VMode
  const VModeStyle = css`
    margin: 0px 50px;
    padding: 0px;
    background-color: ${data.VMode.telemetry === 'BEACON_MODE' || data.VMode.telemetry === 'SAFE_MODE' ? 'Orange' : 'green'};
  `;

  return (
    <div className={containerStyle}>
      <div className={innerContainerStyle}>
        <p>Current UTC Time:</p>
        <p className={innerPValueStyle}>{currentTime}</p>
      </div>
      <div className={innerContainerStyle}>
        <p>Vehicle Mode:</p>
        <p className={cx(VModeStyle)}>{data.VMode.telemetry}</p>
      </div>
      <div className={innerContainerStyle}>
        <p>Current 1-way Light Travel Delay:</p>
        <p className={innerPValueStyle}>{oneWayLightTimeFieldValues} s</p>
      </div>
    </div>
  );
};

export default VModeBox;
