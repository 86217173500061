import React from 'react'
import {InnerData} from './utils';
import { Handle, Position } from 'reactflow';

interface RCSThrusterProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
  }
}


const RCSThruster: React.FC<RCSThrusterProp> = ({data}) => {

  // let id = "";
  // if(data.name === "RCSA Thruster"){
  //   id = "A";
  // } else if(data.name === "RCSB Thruster"){
  //   id = "B";
  // }else{
  //   id = "";
  // }

  return (
    <div>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, top:100}}/>
      <Handle type="target" position={Position.Top} id="target-top-2" style={{opacity:0,left:50, top:120}}/>
      <Handle type="target" position={Position.Top} id="target-top-3" style={{opacity:0, top:170}}/>
      <Handle type="target" position={Position.Top} id="target-top-4" style={{opacity:0,left:250, top:120}}/>
      <Handle type="target" position={Position.Top} id="target-top-5" style={{opacity:0,left:180, top:70}}/>

      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>


        <div >

          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="303px" height="303px" viewBox="-0.5 -0.5 303 303">
            <defs/>
              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
                <g>
                  <path d="M 180 130 L 180 170" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
                <g>
                  <path d="M 300 190 L 300 110" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
                <g>
                  <path d="M 300 190 Q 300 170 180 170" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
                <g>
                  <path d="M 300 110 Q 300 130 180 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
                <g>
                  <path d="M 120 170 L 120 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
                <g>
                  <path d="M 0 110 L 0 190" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
                <g>
                  <path d="M 0 110 Q 0 130 120 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
                </g>
              <g>
                <path d="M 0 190 Q 0 170 120 170" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              </g>
              <g>
                <path d="M 170 120 L 130 120" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              </g>
              <g>
                <path d="M 190 0 L 110 0" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              </g>
              <g><path d="M 110 0 Q 130.5 0.5 130 120" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              </g>
              <g>
                <path d="M 190 0 Q 170.5 0.5 170 120" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 130 180 L 170 180" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
              </g>
              <g>
                <path d="M 110 300 L 190 300" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 190 300 Q 170.5 300.5 170 180" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/></g>
          <g>
            <path d="M 110 300 Q 130.5 300.5 130 180" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
            </g>
            {/* <text x="130" y="70" fill="white" fontSize="30px">4{id}</text>
            <text x="130" y="250" fill="white" fontSize="30px">2{id}</text>

            {id === "A" && <text x="50" y="160" fill="white" fontSize="30px">3{id}</text>}
            {id === "A" && <text x="210" y="160" fill="white" fontSize="30px">1{id}</text>}
            {id === "B" && <text x="50" y="160" fill="white" fontSize="30px">1{id}</text>}
            {id === "B" && <text x="210" y="160" fill="white" fontSize="30px">3{id}</text>} */}
            </g>
            </svg>
                  </div>

    </div>
  );
}

export default RCSThruster;
