import React, { useState } from "react";
import { css } from '@emotion/css';
import { InnerData } from "./utils";

interface MemoryBoxProp {
  data: {
    name: string;
    value: InnerData;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const memoryBoxStyle = css`
  background-color: #333;
  padding: 10px;
  width: 450px;
  color: white;
`;

const memoryNameStyle = css`
  text-align: center;
  margin-bottom: 10px;
`;

const memoryRowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const barContainerStyle = css`
  background-color: black;
  width: 60%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
`;

const upperBarStyle = (width: number) => {
  let backgroundColor;

  if (width < 50) {
    backgroundColor = 'green';
  } else if (width >= 50 && width < 80) {
    backgroundColor = 'yellow';
  } else if (width >= 80 && width < 90) {
    backgroundColor = 'orange';
  } else {
    backgroundColor = 'red';
  }

  return css`
    height: 100%;
    width: ${width}%;
    background-color: ${backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `;
};

const innerText = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MemoryBox: React.FC<MemoryBoxProp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: !prevVisibleTooltips[key]
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  return (
    <div className={memoryBoxStyle}>
      <p className={memoryNameStyle}>{data.name}</p>
      <div>
        {data.value && Object.entries(data.value).map(([key, innerData]) => (
          <div key={key} className={memoryRowStyle}>
            <span onClick={() => handleMneNameClick(key)}>
              {key}
              {visibleTooltips[key] && (
                <span>{innerData.dbData.mne}</span>
              )}
            </span>
            <div className={barContainerStyle}>
              <div className={upperBarStyle(parseFloat(innerData.dbData.telemetry))}>
              </div>
              <span className={innerText}>{innerData.dbData.telemetry}%</span>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemoryBox;
