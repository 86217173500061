import React, {useCallback, useEffect, useState } from 'react';
import ReactFlow, { ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { LocationData, nameToMne } from './utils';
import Panel from 'module/Panel';
import { css } from '@emotion/css';
import Spoc from 'module/Spoc';
import Box from 'module/Box';
import VaryAntennas from './VaryAntennas';
import Prop from '../module/Prop';
import RCS from '../module/RCS';
import Antennas from './Antennas';
import ExxBox from './ExxBox';
import ExxCir from './ExxCir';
import PropThruster from './PropThruster';
import RCSThruster from './RCSThruster';
import LivePlot from './LivePlot';
import Time3dFacade from './TimeFacade';
import MemoryBox from './MemoryBox';
import VModeBox from './VModeBox';
import ExxBoxFdir from './ExxBoxFdir';
import ExxBoxFdirLarge from './ExxBoxFdirLarge';
import Heater from './Heater';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type f unit
  live: string;
  mne: string;
  spacecraft: string;
};

type Field = {
  name: string;
  type: string;
  values: any[]; // Replace 'any' with a more specific type if possible
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  groundData: Field[];
  locationData: LocationData;
  // ... other props if there are any
};

const nodeTypes = {panel:Panel,heater:Heater,vmodeBox:VModeBox,exxFdirBoxLarge:ExxBoxFdirLarge,exxFdirBox:ExxBoxFdir, memoryBox:MemoryBox,liveplot:LivePlot,rcsThruster:RCSThruster,timebox: Time3dFacade,propThruster:PropThruster,exxCir:ExxCir,exxbox:ExxBox,antennas:Antennas,prop:Prop, rcs:RCS,varyAntennas:VaryAntennas,box:Box,spoc:Spoc}

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-panel, .react-flow__node-rcsPanel, .react-flow__node-spoc{
  z-index: 5 !important;
}

.react-flow__node-solarCell{
  z-index: 6 !important;

}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  display: flex;
`;

const App: React.FC<AppProps> = ({dbData, source, width, height, options, groundData, locationData}) => {
  
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onInit = useCallback((instance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);


  function isWithinDifference(unixTimestamp: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;

    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  function convertTimestampToPST(timestamp: number) {
    // Create a new Date object using the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);

    // To convert to PST, get the UTC time and subtract 8 hours for PST
    // Note: JavaScript Date object functions account for Daylight Saving Time changes
    const pstDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 
                           date.getUTCHours() - 8, date.getUTCMinutes(), date.getUTCSeconds());

    // Format the date and time in a readable format
    const formattedDate = pstDate.getFullYear() + '-' + 
                        ('0' + (pstDate.getMonth() + 1)).slice(-2) + '-' + 
                        ('0' + pstDate.getDate()).slice(-2) + ' ' + 
                        ('0' + pstDate.getHours()).slice(-2) + ':' + 
                        ('0' + pstDate.getMinutes()).slice(-2) + ':' + 
                        ('0' + pstDate.getSeconds()).slice(-2);

    return formattedDate;
}

  const getGroundData = useCallback(() => {
    // Safely get the other columns
    const stationColumn = groundData.find(column => column.name === "station");
    const scColumn = groundData.find(column => column.name === "scName");
    const tAosColumn = groundData.find(column => column.name === "tAos");
    const tLosColumn = groundData.find(column => column.name === "tLos");
    const trCodeColumn = groundData.find(column => column.name === "trCode");

    const station = stationColumn ? stationColumn.values[0] : null;
    let scName = scColumn ? scColumn.values[0] : null;
    let tAos = tAosColumn ? tAosColumn.values[0] : null;
    let tLos = tLosColumn ? tLosColumn.values[0] : null;
    let trCode = trCodeColumn ? trCodeColumn.values[0] : null;
  
    return {'SC':scName,'GS':station,  'AOS':convertTimestampToPST(tAos), 'LOS':convertTimestampToPST(tLos), 'Tr':trCode};
  },[groundData]);

  const groundList = getGroundData();

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex]) : "";

    // Append the degree symbol tothe unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC' || unit === 'deg C' || unit === 'Celsius') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None' || unit === 'na' || unit === 'N/A' ){
      unit = null
    }else if(unit === 'bool'){
      unit = 'B'
    }else if(unit === 'count' || unit === 'Counts' || unit === 'counts' || unit === 'Counter'){
      unit = 'CT'
    }else if(unit === 'rad/s'){
      unit = 'R/S'
    }else if(unit === 'Percent' || unit === 'percent' || unit === 'Percentage' || unit === 'percentage'){
      unit = '%'
    }
  
// Special handling for 'WFI HV' and 'NFI HV'
if (mne === 'cdh_version_fc_boot_count_time') {
  cnvValue = parseFloat(cnvValue).toExponential(2);
} else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
  // Check if cnvValue is a number
  let numValue = parseFloat(cnvValue);

  // Convert to exponential notation if the integer part has more than 7 digits
  if (Math.abs(numValue) >= 10000000) {
    cnvValue = numValue.toExponential(2);
  } else if (cnvValue.includes('.')) {
    // It's a float, parse and keep 2 decimal places
    cnvValue = numValue.toFixed(2);
  }
} 
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    // Get the vehicle mode
    const vMode = getDataFromSource('state_spinand0_last_cmd_vehicle_mode_agg').cnvValue;
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      // Choose suffix based on vMode
      let suffix = vMode === "SAFE_MODE" ? "_sh" : "_agg";
      let newMne = mne + suffix;
      let data = getDataFromSource(newMne.toLowerCase());
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, nameToMne, source]);
  
  

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Payload', type: 'panel', position:{x:150, y:50}, data:{'source':source,width:1300, height:800,name:"Payload", hlink:"http://ops-display.ssl.berkeley.edu/d/c4d12ad6-f45b-44ac-8cd5-a755ea2c142a/escapade-payload-gui?orgId=1&refresh=5s&from=1721643945501&to=1721687145501"}},
    { id: 'CDH', type: 'panel', position:{x:150, y:850}, data:{'source':source,width:1350, height:700,name:"CDH", hlink:"http://ops-display.ssl.berkeley.edu/d/edpzc6xclv4zkd/escapade-cdh?orgId=1&refresh=5s&from=1721644020030&to=1721687220030"}},
    { id: 'Comm', type: 'panel', position:{x:1500, y:850}, data:{'source':source,width:1200, height:700,name:"Comm", hlink:"http://ops-display.ssl.berkeley.edu/d/adlq63qhlb9xca/escapade-comm?orgId=1&refresh=5s"}},
    { id: 'Prop', type: 'panel', position:{x:150, y:1550}, data:{'source':source,width:3250, height:1050,name:"Prop", hlink:"http://ops-display.ssl.berkeley.edu/d/cdmiaiqo317nke/escapade-prop?orgId=1&refresh=5s"}},
    { id: 'EPS', type: 'panel', position:{x:1450, y:50}, data:{'source':source,width:1950, height:800, name:"EPS", hlink:"http://ops-display.ssl.berkeley.edu/d/fdiy1s7uoj85cf/escapade-eps?orgId=1&refresh=5s&from=1721644177832&to=1721687377833"}},
    { id: 'GNC', type: 'panel', position:{x:2700, y:850}, data:{'source':source,width:700, height:700, name:"GNC", hlink:"http://ops-display.ssl.berkeley.edu/d/cdpvpsrck6k8wc/escapade-gnc?orgId=1&refresh=5s&from=1721644200155&to=1721687400155"}},

    { id: 'PIU', type: 'exxbox',parentNode:'Prop', position:{x:1850, y:50}, data:{name:'PIU',oriHeight:'150px', value:{"Temp":{'dbData':telemetryDictionary['Piu Temp'],'fname':''}}}},

    { id: 'N2T4', type: 'exxCir',parentNode:'Prop', position:{x:2325, y:250}, data:{name:'N2T4', value:{"N2 T4":{'dbData':telemetryDictionary['N2 B4 Temp Raw'],'fname':''}, "N2 TP4":{'dbData':telemetryDictionary['N2 TP4 Raw'],'fname':''}}}},
    { id: 'N2T3', type: 'exxCir',parentNode:'Prop', position:{x:2100, y:250}, data:{name:'N2T3', value:{"N2 T3":{'dbData':telemetryDictionary['N2 B3 Temp Raw'],'fname':''}, "N2 TP3":{'dbData':telemetryDictionary['N2 TP3 Raw'],'fname':''},}}},
    { id: 'N2T2', type: 'exxCir',parentNode:'Prop', position:{x:1875, y:250}, data:{name:'N2T2', value:{"N2 T2":{'dbData':telemetryDictionary['N2 B2 Temp Raw'],'fname':''}, "N2 TP2":{'dbData':telemetryDictionary['N2 TP2 Raw'],'fname':''}}}},
    { id: 'N2T1', type: 'exxCir',parentNode:'Prop', position:{x:1650, y:250}, data:{name:'N2T1', value:{"N2 T1":{'dbData':telemetryDictionary['N2 B1 Temp Raw'],'fname':''}, "N2 TP1":{'dbData':telemetryDictionary['N2 TP1 Raw'],'fname':''},}}},
    { id: 'N2Htr', type: 'heater',parentNode:'Prop', position:{x:2000, y:450}, data:{name:'N2 Htr',pwr:telemetryDictionary['N2 Htr State']}},
    { id: 'RCA', type: 'exxbox',parentNode:'Prop', position:{x:1650, y:600}, data:{name:'RCS A',oriHeight:'150px', value:{"A Temp Raw":{'dbData':telemetryDictionary['RCS A Temp Raw'],'fname':''}}}},
    { id: 'RCB', type: 'exxbox',parentNode:'Prop', position:{x:2100, y:600}, data:{name:'RCS B',oriHeight:'150px', value:{"B Temp Raw":{'dbData':telemetryDictionary['RCS B Temp Raw'],'fname':''}}}},
    { id: 'RCSA Htr', type: 'heater',parentNode:'Prop', position:{x:1800, y:775}, data:{name:'A Htr',pwr:telemetryDictionary['RCS A Htr State']}},
    { id: 'RCSB Htr', type: 'heater',parentNode:'Prop', position:{x:2250, y:775}, data:{name:'B Htr',pwr:telemetryDictionary['RCS A Htr State']}},
    
    { id: 'PropDeck', type: 'exxbox',parentNode:'Prop', position:{x:2575, y:525}, data:{name:'Prop Deck', value:{"T1 Raw":{'dbData':telemetryDictionary['Prop Deck T1 Raw'],'fname':''},"T2 Raw":{'dbData':telemetryDictionary['Prop Deck T2 Raw'],'fname':''},"T3 Raw":{'dbData':telemetryDictionary['Prop Deck T3 Raw'],'fname':''},"T4 Raw":{'dbData':telemetryDictionary['Prop Deck T4 Raw'],'fname':''}}}},
    { id: 'Fluidics', type: 'exxbox',parentNode:'Prop', position:{x:2575, y:50}, data:{name:'Fluidics', value:{"T1":{'dbData':telemetryDictionary['Fluidics T1'],'fname':''},"T2":{'dbData':telemetryDictionary['Fluidics T2'],'fname':''},"T3":{'dbData':telemetryDictionary['Fluidics T3'],'fname':''},"T4":{'dbData':telemetryDictionary['Fluidics T4'],'fname':''}}}},
    { id: 'PropDeckHtr1', type: 'heater',parentNode:'Prop', position:{x:3050, y:550}, data:{name:'Prop Htr1',pwr:telemetryDictionary['Prop Deck Htr1 State']}},
    { id: 'PropDeckHtr2', type: 'heater',parentNode:'Prop', position:{x:3050, y:675}, data:{name:'Prop Htr2',pwr:telemetryDictionary['Prop Deck Htr2 State']}},
    { id: 'PropDeckHtr3', type: 'heater',parentNode:'Prop', position:{x:3050, y:800}, data:{name:'Prop Htr3',pwr:telemetryDictionary['Prop Deck Htr3 State']}},
    { id: 'PropDeckHtr4', type: 'heater',parentNode:'Prop', position:{x:3050, y:925}, data:{name:'Prop Htr4',pwr:telemetryDictionary['Prop Deck Htr4 State']}},

    { id: 'HeReg', type: 'exxbox',parentNode:'Prop', position:{x:575, y:250}, data:{name:'HeReg',oriHeight:'120px',oriWidth:'380px', value:{"Temp Raw":{'dbData':telemetryDictionary['He Reg Temp Raw'],'fname':''}}}},
    { id: 'HeB2', type: 'exxCir',parentNode:'Prop', position:{x:950, y:50}, data:{name:'HeB2', value:{"T R":{'dbData':telemetryDictionary['He Bottle T2 Raw'],'fname':''}}}},
    { id: 'HeB1', type: 'exxCir',parentNode:'Prop', position:{x:400, y:50}, data:{name:'HeB1', value:{"T R":{'dbData':telemetryDictionary['He Bottle T1 Raw'],'fname':''}}}},
    { id: 'HeB1Htr', type: 'heater',parentNode:'Prop', position:{x:585, y:75}, data:{name:'B1 Htr',pwr:telemetryDictionary['He Bottle1 Htr State']}},
    { id: 'HeB2Htr', type: 'heater',parentNode:'Prop', position:{x:800, y:75}, data:{name:'B2 Htr',pwr:telemetryDictionary['He Bottle2 Htr State']}},
    { id: 'FuT2', type: 'exxCir',parentNode:'Prop', position:{x:1175, y:400}, data:{name:'FuT2', value:{"T2 N Temp":{'dbData':telemetryDictionary['Fu T2 N Temp'],'fname':''},"T2 S Temp":{'dbData':telemetryDictionary['Fu T2 S Temp'],'fname':''},"T2 P Temp":{'dbData':telemetryDictionary['Fu T2 P Temp'],'fname':''},}}},
    { id: 'FuT1', type: 'exxCir',parentNode:'Prop', position:{x:960, y:400}, data:{name:'FuT1', value:{"T1 N Temp":{'dbData':telemetryDictionary['Fu T1 N Temp'],'fname':''},"T1 S Temp":{'dbData':telemetryDictionary['Fu T1 S Temp'],'fname':''},"T1 P Temp":{'dbData':telemetryDictionary['Fu T1 P Temp'],'fname':''},}}},
    { id: 'FuT1 N Htr', type: 'heater',parentNode:'Prop', position:{x:800, y:425}, data:{name:'T1 N Htr',pwr:telemetryDictionary['Fu T1 N Htr State']}},
    { id: 'FuT1 S Htr', type: 'heater',parentNode:'Prop', position:{x:800, y:525}, data:{name:'T1 S Htr',pwr:telemetryDictionary['Fu T1 S Htr State']}},
    { id: 'FuT2 N Htr', type: 'heater',parentNode:'Prop', position:{x:1375, y:425}, data:{name:'T2 N Htr',pwr:telemetryDictionary['Fu T2 N Htr State']}},
    { id: 'FuT2 S Htr', type: 'heater',parentNode:'Prop', position:{x:1375, y:525}, data:{name:'T2 S Htr',pwr:telemetryDictionary['Fu T2 S Htr State']}},
    { id: 'OxT2', type: 'exxCir',parentNode:'Prop', position:{x:400, y:400}, data:{name:'OxT2', value:{"T2 N Temp":{'dbData':telemetryDictionary['Ox T2 N Temp'],'fname':''},"T2 S Temp":{'dbData':telemetryDictionary['Ox T2 S Temp'],'fname':''},"T2 P Temp":{'dbData':telemetryDictionary['Ox T2 P Temp'],'fname':''},}}},
    { id: 'OxT1', type: 'exxCir',parentNode:'Prop', position:{x:180, y:400}, data:{name:'OxT1', value:{"T1 N Temp":{'dbData':telemetryDictionary['Ox T1 N Temp'],'fname':''},"T1 S Temp":{'dbData':telemetryDictionary['Ox T1 S Temp'],'fname':''},"T1 P Temp":{'dbData':telemetryDictionary['Ox T1 P Temp'],'fname':''},}}},
    { id: 'OxT1 N Htr', type: 'heater',parentNode:'Prop', position:{x:25, y:425}, data:{name:'T1 N Htr',pwr:telemetryDictionary['Ox T1 N Htr State']}},
    { id: 'OxT1 S Htr', type: 'heater',parentNode:'Prop', position:{x:25, y:525}, data:{name:'T1 S Htr',pwr:telemetryDictionary['Ox T1 S Htr State']}},
    { id: 'OxT2 N Htr', type: 'heater',parentNode:'Prop', position:{x:600, y:425}, data:{name:'T2 N Htr',pwr:telemetryDictionary['Ox T2 N Htr State']}},
    { id: 'OxT2 S Htr', type: 'heater',parentNode:'Prop', position:{x:600, y:525}, data:{name:'T2 S Htr',pwr:telemetryDictionary['Ox T2 S Htr State']}},
    { id: 'FuEng', type: 'exxbox',parentNode:'Prop', position:{x:950, y:625}, data:{name:'Fu',oriHeight:'150px', value:{ "Fh Temp":{'dbData':telemetryDictionary['Fh Fu Temp'],'fname':''},"Eng Temp":{'dbData':telemetryDictionary['Eng Fu Temp'],'fname':""}}}},
    { id: 'Fh Fu Htr', type: 'heater',parentNode:'Prop', position:{x:975, y:785}, data:{name:'Fh Htr',pwr:telemetryDictionary['Fh Fu Htr State']}},
    { id: 'Eng Fu Htr', type: 'heater',parentNode:'Prop', position:{x:1225, y:785}, data:{name:'Eng Htr',pwr:telemetryDictionary['Eng Fu Htr State']}},
    { id: 'OxEng', type: 'exxbox',parentNode:'Prop', position:{x:150, y:625}, data:{name:'Ox',oriHeight:'150px', value:{ "Fh Temp":{'dbData':telemetryDictionary['Fh Ox Temp'],'fname':''},"Eng Temp":{'dbData':telemetryDictionary['Eng Ox Temp'],'fname':""}}}},
    { id: 'Ox Fu Htr', type: 'heater',parentNode:'Prop', position:{x:175, y:785}, data:{name:'Fh Htr',pwr:telemetryDictionary['Fh Ox Htr State']}},
    { id: 'Eng Ox Htr', type: 'heater',parentNode:'Prop', position:{x:425, y:785}, data:{name:'Eng Htr',pwr:telemetryDictionary['Eng Ox Htr State']}},
    { id: 'TVC', type: 'exxbox',parentNode:'Prop', position:{x:550, y:875}, data:{name:'TVC',oriHeight:'150px', value:{"TvcX Temp":{'dbData':telemetryDictionary['TvcX Temp'],'fname':''},"TvcZ Temp":{'dbData':telemetryDictionary['TvcZ Temp'],'fname':''}}}},
    { id: 'TVCHtr', type: 'heater',parentNode:'Prop', position:{x:685, y:785}, data:{name:'TVC Htr',pwr:telemetryDictionary['Tvc Htr State']}},
    
    { id: 'St', type: 'exxbox',parentNode:'GNC', position:{x:117.5, y:350}, data:{name:'Star Tracker',oriHeight:'150px', value:{"A Temp Raw":{'dbData':telemetryDictionary['St A Temp Raw'],'fname':''},"B Temp Raw":{'dbData':telemetryDictionary['St B Temp Raw'],'fname':''}}}},
    { id: 'FSS', type: 'exxbox',parentNode:'GNC', position:{x:117.5, y:100}, data:{name:'FSS',oriHeight:'150px', value:{"A Temp":{'dbData':telemetryDictionary['Fss A Temp'],'fname':''},"B Temp":{'dbData':telemetryDictionary['Fss B Temp'],'fname':''}}}},
    
    { id: 'Radiator', type: 'exxbox',parentNode:'Comm', position:{x:675, y:150}, data:{name:'Radiator', exWidth:'525px',value:{"Radio Temp Raw":{'dbData':telemetryDictionary['Radiator Radio Temp Raw'],'fname':''},"Spoc Temp Raw":{'dbData':telemetryDictionary['Radiator Spoc Temp Raw'],'fname':''}}}},
    { id: 'Radio Htr', type: 'heater',parentNode:'Comm', position:{x:675, y:50}, data:{name:'Radio Htr',pwr:telemetryDictionary['Radiator Radio Htr State']}},
    { id: 'Spoc Htr', type: 'heater',parentNode:'Comm', position:{x:900, y:50}, data:{name:'Spoc Htr',pwr:telemetryDictionary['Radiator Spoc Htr State']}},
    { id: 'Hpa', type: 'exxbox',parentNode:'Comm', position:{x:50, y:425}, data:{name:'Hpa',oriHeight:'150px', exWidth:'500px',value:{"Pcu Temp Raw":{'dbData':telemetryDictionary['Hpa Pcu Temp Raw'],'fname':''},"Temp Raw":{'dbData':telemetryDictionary['Hpa Temp Raw'],'fname':''}}}},
    { id: 'HPA Htr', type: 'heater',parentNode:'Comm', position:{x:185, y:325}, data:{name:'Hpa Htr',pwr:telemetryDictionary['Hpa Htr State']}},
    { id: 'HGA', type: 'exxbox',parentNode:'Comm', position:{x:50, y:125}, data:{name:'HGA',oriHeight:'150px', exWidth:'475px',value:{"Temp Raw":{'dbData':telemetryDictionary['HGA Temp Raw'],'fname':''}}}},

    { id: 'Spoc', type: 'exxbox',parentNode:'CDH', position:{x:775, y:125}, data:{name:'Spoc', exWidth:'600px', value:{"Internal Temp Raw":{'dbData':telemetryDictionary['Spoc Int Temp Raw'],'fname':''},"Spare 15 Temp Raw":{'dbData':telemetryDictionary['Spare 15 Temp Raw'],'fname':''},"Spare 34 Temp Raw":{'dbData':telemetryDictionary['Spare 34 Temp Raw'],'fname':''}}}},
    { id: 'AviDeck', type: 'exxbox',parentNode:'CDH', position:{x:50, y:125}, data:{name:'Avi Deck', value:{"T1 Raw":{'dbData':telemetryDictionary['Avi Deck T1 Raw'],'fname':''},"T2 Raw":{'dbData':telemetryDictionary['Avi Deck T2 Raw'],'fname':''},"T3 Raw":{'dbData':telemetryDictionary['Avi Deck T3 Raw'],'fname':''},"T4 Raw":{'dbData':telemetryDictionary['Avi Deck T4 Raw'],'fname':''}}}},
    { id: 'AviDeckHtr1', type: 'heater',parentNode:'CDH', position:{x:550, y:125}, data:{name:'Avi Htr1',pwr:telemetryDictionary['Avi Deck Htr1 State']}},
    { id: 'AviDeckHtr2', type: 'heater',parentNode:'CDH', position:{x:550, y:225}, data:{name:'Avi Htr2',pwr:telemetryDictionary['Avi Deck Htr2 State']}},
    { id: 'AviDeckHtr3', type: 'heater',parentNode:'CDH', position:{x:550, y:325}, data:{name:'Avi Htr3',pwr:telemetryDictionary['Avi Deck Htr3 State']}},
    { id: 'AviDeckHtr4', type: 'heater',parentNode:'CDH', position:{x:550, y:425}, data:{name:'Avi Htr4',pwr:telemetryDictionary['Avi Deck Htr4 State']}},

    { id: 'BCR', type: 'exxbox',parentNode:'EPS', position:{x:100, y:200}, data:{name:'BCR', value:{"Bcr Temp Raw":{'dbData':telemetryDictionary['Bcr Temp Raw'],'fname':''}}}},
    { id: 'SolarArray', type: 'exxbox',parentNode:'EPS', position:{x:1300, y:200}, data:{name:'SolarArray', exWidth:'650px', value:{"Nx Temp Raw":{'dbData':telemetryDictionary['Solar Nx Temp Raw'],'fname':''},"Px Temp Raw":{'dbData':telemetryDictionary['Solar Px Temp Raw'],'fname':''}, "Hinge In Nx T Raw":{'dbData':telemetryDictionary['Hinge Inboard Nx T Raw'],'fname':''}, "Hinge In Px T Raw":{'dbData':telemetryDictionary['Hinge Inboard Px T Raw'],'fname':''}, "Hinge Out Nx T Raw":{'dbData':telemetryDictionary['Hinge Outboard Nx T Raw'],'fname':''}, "Hinge Out Px T Raw":{'dbData':telemetryDictionary['Hinge Outboard Px T Raw'],'fname':''}, "Hinge Root Nx T Raw":{'dbData':telemetryDictionary['Hinge Root Nx T Raw'],'fname':''}, "Hinge Root Px T Raw":{'dbData':telemetryDictionary['Hinge Root Px T Raw'],'fname':''}}}},
    { id: 'Outboard Htr', type: 'heater',parentNode:'EPS', position:{x:1500, y:100}, data:{name:'Outboard Htr',pwr:telemetryDictionary['Hinge Outboard Htr State']}},
    { id: 'Root Htr', type: 'heater',parentNode:'EPS', position:{x:1300, y:100}, data:{name:'Root Htr',pwr:telemetryDictionary['Hinge Root Htr State']}},
    { id: 'Battery', type: 'exxbox',parentNode:'EPS', position:{x:700, y:200}, data:{name:'Battery', value:{"Bim Temp Raw":{'dbData':telemetryDictionary['Bim Temp Raw'],'fname':''},"Batt A Temp":{'dbData':telemetryDictionary['Bim Battery A Temp'],'fname':''},"Batt B Temp":{'dbData':telemetryDictionary['Bim Battery B Temp'],'fname':''},"Batt C Temp":{'dbData':telemetryDictionary['Bim Battery C Temp'],'fname':''},"Batt D Temp":{'dbData':telemetryDictionary['Bim Battery D Temp'],'fname':''},"Batt E Temp":{'dbData':telemetryDictionary['Bim Battery E Temp'],'fname':''}}}},

    { id: 'Boom', type: 'exxbox',parentNode:'Payload', position:{x:700, y:500}, data:{name:'Boom',oriHeight:'150px', value:{"Temp Raw":{'dbData':telemetryDictionary['Boom Temp Raw'],'fname':''}}}},
    { id: 'EESA', type: 'exxbox',parentNode:'Payload', position:{x:700, y:200}, data:{name:'EESA',oriHeight:'150px', value:{"T1 Raw":{'dbData':telemetryDictionary['EESA T1 Raw'],'fname':''}, "T2 Raw":{'dbData':telemetryDictionary['EESA T2 Raw'],'fname':''}}}},
    { id: 'EESA Htr', type: 'heater',parentNode:'Payload', position:{x:835, y:100}, data:{name:'EESA Htr',pwr:telemetryDictionary['EESA Htr State']}},
    { id: 'EIDPU', type: 'exxbox',parentNode:'Payload', position:{x:100, y:200}, data:{name:'EIDPU',oriHeight:'150px', value:{"T1 Raw":{'dbData':telemetryDictionary['EIDPU T1 Raw'],'fname':''}, "T2 Raw":{'dbData':telemetryDictionary['EIDPU T1 Raw'],'fname':''}, "Tif Raw":{'dbData':telemetryDictionary['EIDPU Tif Raw'],'fname':''}}}},
    { id: 'EIDPU Htr', type: 'heater',parentNode:'Payload', position:{x:235, y:100}, data:{name:'EIDPU Htr',pwr:telemetryDictionary['EIDPU Htr State']}},
    { id: 'Cam', type: 'exxbox',parentNode:'Payload', position:{x:100, y:500}, data:{name:'Cam',oriHeight:'150px', value:{"Temp Raw":{'dbData':telemetryDictionary['Cam Temp Raw'],'fname':''}}}},

    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:150, y:-62}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg']}},

]);

  const createNodes = (telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Payload', type: 'panel', position:{x:150, y:50}, data:{'source':source,width:1300, height:800,name:"Payload", hlink:"http://ops-display.ssl.berkeley.edu/d/c4d12ad6-f45b-44ac-8cd5-a755ea2c142a/escapade-payload-gui?orgId=1&refresh=5s&from=1721643945501&to=1721687145501"}},
      { id: 'CDH', type: 'panel', position:{x:150, y:850}, data:{'source':source,width:1350, height:700,name:"CDH", hlink:"http://ops-display.ssl.berkeley.edu/d/edpzc6xclv4zkd/escapade-cdh?orgId=1&refresh=5s&from=1721644020030&to=1721687220030"}},
      { id: 'Comm', type: 'panel', position:{x:1500, y:850}, data:{'source':source,width:1200, height:700,name:"Comm", hlink:"http://ops-display.ssl.berkeley.edu/d/adlq63qhlb9xca/escapade-comm?orgId=1&refresh=5s"}},
      { id: 'Prop', type: 'panel', position:{x:150, y:1550}, data:{'source':source,width:3250, height:1050,name:"Prop", hlink:"http://ops-display.ssl.berkeley.edu/d/cdmiaiqo317nke/escapade-prop?orgId=1&refresh=5s"}},
      { id: 'EPS', type: 'panel', position:{x:1450, y:50}, data:{'source':source,width:1950, height:800, name:"EPS", hlink:"http://ops-display.ssl.berkeley.edu/d/fdiy1s7uoj85cf/escapade-eps?orgId=1&refresh=5s&from=1721644177832&to=1721687377833"}},
      { id: 'GNC', type: 'panel', position:{x:2700, y:850}, data:{'source':source,width:700, height:700, name:"GNC", hlink:"http://ops-display.ssl.berkeley.edu/d/cdpvpsrck6k8wc/escapade-gnc?orgId=1&refresh=5s&from=1721644200155&to=1721687400155"}},

      { id: 'PIU', type: 'exxbox',parentNode:'Prop', position:{x:1850, y:50}, data:{name:'PIU',oriHeight:'150px', value:{"Temp":{'dbData':telemetryDictionary['Piu Temp'],'fname':''}}}},

      { id: 'N2T4', type: 'exxCir',parentNode:'Prop', position:{x:2325, y:250}, data:{name:'N2T4', value:{"N2 T4":{'dbData':telemetryDictionary['N2 B4 Temp Raw'],'fname':''}, "N2 TP4":{'dbData':telemetryDictionary['N2 TP4 Raw'],'fname':''}}}},
      { id: 'N2T3', type: 'exxCir',parentNode:'Prop', position:{x:2100, y:250}, data:{name:'N2T3', value:{"N2 T3":{'dbData':telemetryDictionary['N2 B3 Temp Raw'],'fname':''}, "N2 TP3":{'dbData':telemetryDictionary['N2 TP3 Raw'],'fname':''},}}},
      { id: 'N2T2', type: 'exxCir',parentNode:'Prop', position:{x:1875, y:250}, data:{name:'N2T2', value:{"N2 T2":{'dbData':telemetryDictionary['N2 B2 Temp Raw'],'fname':''}, "N2 TP2":{'dbData':telemetryDictionary['N2 TP2 Raw'],'fname':''}}}},
      { id: 'N2T1', type: 'exxCir',parentNode:'Prop', position:{x:1650, y:250}, data:{name:'N2T1', value:{"N2 T1":{'dbData':telemetryDictionary['N2 B1 Temp Raw'],'fname':''}, "N2 TP1":{'dbData':telemetryDictionary['N2 TP1 Raw'],'fname':''},}}},
      { id: 'N2Htr', type: 'heater',parentNode:'Prop', position:{x:2000, y:450}, data:{name:'N2 Htr',pwr:telemetryDictionary['N2 Htr State']}},
      { id: 'RCA', type: 'exxbox',parentNode:'Prop', position:{x:1650, y:600}, data:{name:'RCS A',oriHeight:'150px', value:{"A Temp Raw":{'dbData':telemetryDictionary['RCS A Temp Raw'],'fname':''}}}},
      { id: 'RCB', type: 'exxbox',parentNode:'Prop', position:{x:2100, y:600}, data:{name:'RCS B',oriHeight:'150px', value:{"B Temp Raw":{'dbData':telemetryDictionary['RCS B Temp Raw'],'fname':''}}}},
      { id: 'RCSA Htr', type: 'heater',parentNode:'Prop', position:{x:1800, y:775}, data:{name:'A Htr',pwr:telemetryDictionary['RCS A Htr State']}},
      { id: 'RCSB Htr', type: 'heater',parentNode:'Prop', position:{x:2250, y:775}, data:{name:'B Htr',pwr:telemetryDictionary['RCS A Htr State']}},
      
      { id: 'PropDeck', type: 'exxbox',parentNode:'Prop', position:{x:2575, y:525}, data:{name:'Prop Deck', value:{"T1 Raw":{'dbData':telemetryDictionary['Prop Deck T1 Raw'],'fname':''},"T2 Raw":{'dbData':telemetryDictionary['Prop Deck T2 Raw'],'fname':''},"T3 Raw":{'dbData':telemetryDictionary['Prop Deck T3 Raw'],'fname':''},"T4 Raw":{'dbData':telemetryDictionary['Prop Deck T4 Raw'],'fname':''}}}},
      { id: 'Fluidics', type: 'exxbox',parentNode:'Prop', position:{x:2575, y:50}, data:{name:'Fluidics', value:{"T1":{'dbData':telemetryDictionary['Fluidics T1'],'fname':''},"T2":{'dbData':telemetryDictionary['Fluidics T2'],'fname':''},"T3":{'dbData':telemetryDictionary['Fluidics T3'],'fname':''},"T4":{'dbData':telemetryDictionary['Fluidics T4'],'fname':''}}}},
      { id: 'PropDeckHtr1', type: 'heater',parentNode:'Prop', position:{x:3050, y:550}, data:{name:'Prop Htr1',pwr:telemetryDictionary['Prop Deck Htr1 State']}},
      { id: 'PropDeckHtr2', type: 'heater',parentNode:'Prop', position:{x:3050, y:675}, data:{name:'Prop Htr2',pwr:telemetryDictionary['Prop Deck Htr2 State']}},
      { id: 'PropDeckHtr3', type: 'heater',parentNode:'Prop', position:{x:3050, y:800}, data:{name:'Prop Htr3',pwr:telemetryDictionary['Prop Deck Htr3 State']}},
      { id: 'PropDeckHtr4', type: 'heater',parentNode:'Prop', position:{x:3050, y:925}, data:{name:'Prop Htr4',pwr:telemetryDictionary['Prop Deck Htr4 State']}},

      { id: 'HeReg', type: 'exxbox',parentNode:'Prop', position:{x:575, y:250}, data:{name:'HeReg',oriHeight:'120px',oriWidth:'380px', value:{"Temp Raw":{'dbData':telemetryDictionary['He Reg Temp Raw'],'fname':''}}}},
      { id: 'HeB2', type: 'exxCir',parentNode:'Prop', position:{x:950, y:50}, data:{name:'HeB2', value:{"T R":{'dbData':telemetryDictionary['He Bottle T2 Raw'],'fname':''}}}},
      { id: 'HeB1', type: 'exxCir',parentNode:'Prop', position:{x:400, y:50}, data:{name:'HeB1', value:{"T R":{'dbData':telemetryDictionary['He Bottle T1 Raw'],'fname':''}}}},
      { id: 'HeB1Htr', type: 'heater',parentNode:'Prop', position:{x:585, y:75}, data:{name:'B1 Htr',pwr:telemetryDictionary['He Bottle1 Htr State']}},
      { id: 'HeB2Htr', type: 'heater',parentNode:'Prop', position:{x:800, y:75}, data:{name:'B2 Htr',pwr:telemetryDictionary['He Bottle2 Htr State']}},
      { id: 'FuT2', type: 'exxCir',parentNode:'Prop', position:{x:1175, y:400}, data:{name:'FuT2', value:{"T2 N Temp":{'dbData':telemetryDictionary['Fu T2 N Temp'],'fname':''},"T2 S Temp":{'dbData':telemetryDictionary['Fu T2 S Temp'],'fname':''},"T2 P Temp":{'dbData':telemetryDictionary['Fu T2 P Temp'],'fname':''},}}},
      { id: 'FuT1', type: 'exxCir',parentNode:'Prop', position:{x:960, y:400}, data:{name:'FuT1', value:{"T1 N Temp":{'dbData':telemetryDictionary['Fu T1 N Temp'],'fname':''},"T1 S Temp":{'dbData':telemetryDictionary['Fu T1 S Temp'],'fname':''},"T1 P Temp":{'dbData':telemetryDictionary['Fu T1 P Temp'],'fname':''},}}},
      { id: 'FuT1 N Htr', type: 'heater',parentNode:'Prop', position:{x:800, y:425}, data:{name:'T1 N Htr',pwr:telemetryDictionary['Fu T1 N Htr State']}},
      { id: 'FuT1 S Htr', type: 'heater',parentNode:'Prop', position:{x:800, y:525}, data:{name:'T1 S Htr',pwr:telemetryDictionary['Fu T1 S Htr State']}},
      { id: 'FuT2 N Htr', type: 'heater',parentNode:'Prop', position:{x:1375, y:425}, data:{name:'T2 N Htr',pwr:telemetryDictionary['Fu T2 N Htr State']}},
      { id: 'FuT2 S Htr', type: 'heater',parentNode:'Prop', position:{x:1375, y:525}, data:{name:'T2 S Htr',pwr:telemetryDictionary['Fu T2 S Htr State']}},
      { id: 'OxT2', type: 'exxCir',parentNode:'Prop', position:{x:400, y:400}, data:{name:'OxT2', value:{"T2 N Temp":{'dbData':telemetryDictionary['Ox T2 N Temp'],'fname':''},"T2 S Temp":{'dbData':telemetryDictionary['Ox T2 S Temp'],'fname':''},"T2 P Temp":{'dbData':telemetryDictionary['Ox T2 P Temp'],'fname':''},}}},
      { id: 'OxT1', type: 'exxCir',parentNode:'Prop', position:{x:180, y:400}, data:{name:'OxT1', value:{"T1 N Temp":{'dbData':telemetryDictionary['Ox T1 N Temp'],'fname':''},"T1 S Temp":{'dbData':telemetryDictionary['Ox T1 S Temp'],'fname':''},"T1 P Temp":{'dbData':telemetryDictionary['Ox T1 P Temp'],'fname':''},}}},
      { id: 'OxT1 N Htr', type: 'heater',parentNode:'Prop', position:{x:25, y:425}, data:{name:'T1 N Htr',pwr:telemetryDictionary['Ox T1 N Htr State']}},
      { id: 'OxT1 S Htr', type: 'heater',parentNode:'Prop', position:{x:25, y:525}, data:{name:'T1 S Htr',pwr:telemetryDictionary['Ox T1 S Htr State']}},
      { id: 'OxT2 N Htr', type: 'heater',parentNode:'Prop', position:{x:600, y:425}, data:{name:'T2 N Htr',pwr:telemetryDictionary['Ox T2 N Htr State']}},
      { id: 'OxT2 S Htr', type: 'heater',parentNode:'Prop', position:{x:600, y:525}, data:{name:'T2 S Htr',pwr:telemetryDictionary['Ox T2 S Htr State']}},
      { id: 'FuEng', type: 'exxbox',parentNode:'Prop', position:{x:950, y:625}, data:{name:'Fu',oriHeight:'150px', value:{ "Fh Temp":{'dbData':telemetryDictionary['Fh Fu Temp'],'fname':''},"Eng Temp":{'dbData':telemetryDictionary['Eng Fu Temp'],'fname':""}}}},
      { id: 'Fh Fu Htr', type: 'heater',parentNode:'Prop', position:{x:975, y:785}, data:{name:'Fh Htr',pwr:telemetryDictionary['Fh Fu Htr State']}},
      { id: 'Eng Fu Htr', type: 'heater',parentNode:'Prop', position:{x:1225, y:785}, data:{name:'Eng Htr',pwr:telemetryDictionary['Eng Fu Htr State']}},
      { id: 'OxEng', type: 'exxbox',parentNode:'Prop', position:{x:150, y:625}, data:{name:'Ox',oriHeight:'150px', value:{ "Fh Temp":{'dbData':telemetryDictionary['Fh Ox Temp'],'fname':''},"Eng Temp":{'dbData':telemetryDictionary['Eng Ox Temp'],'fname':""}}}},
      { id: 'Ox Fu Htr', type: 'heater',parentNode:'Prop', position:{x:175, y:785}, data:{name:'Fh Htr',pwr:telemetryDictionary['Fh Ox Htr State']}},
      { id: 'Eng Ox Htr', type: 'heater',parentNode:'Prop', position:{x:425, y:785}, data:{name:'Eng Htr',pwr:telemetryDictionary['Eng Ox Htr State']}},
      { id: 'TVC', type: 'exxbox',parentNode:'Prop', position:{x:550, y:875}, data:{name:'TVC',oriHeight:'150px', value:{"TvcX Temp":{'dbData':telemetryDictionary['TvcX Temp'],'fname':''},"TvcZ Temp":{'dbData':telemetryDictionary['TvcZ Temp'],'fname':''}}}},
      { id: 'TVCHtr', type: 'heater',parentNode:'Prop', position:{x:685, y:785}, data:{name:'TVC Htr',pwr:telemetryDictionary['Tvc Htr State']}},
      
      { id: 'St', type: 'exxbox',parentNode:'GNC', position:{x:117.5, y:350}, data:{name:'Star Tracker',oriHeight:'150px', value:{"A Temp Raw":{'dbData':telemetryDictionary['St A Temp Raw'],'fname':''},"B Temp Raw":{'dbData':telemetryDictionary['St B Temp Raw'],'fname':''}}}},
      { id: 'FSS', type: 'exxbox',parentNode:'GNC', position:{x:117.5, y:100}, data:{name:'FSS',oriHeight:'150px', value:{"A Temp":{'dbData':telemetryDictionary['Fss A Temp'],'fname':''},"B Temp":{'dbData':telemetryDictionary['Fss B Temp'],'fname':''}}}},
      
      { id: 'Radiator', type: 'exxbox',parentNode:'Comm', position:{x:675, y:150}, data:{name:'Radiator', exWidth:'525px',value:{"Radio Temp Raw":{'dbData':telemetryDictionary['Radiator Radio Temp Raw'],'fname':''},"Spoc Temp Raw":{'dbData':telemetryDictionary['Radiator Spoc Temp Raw'],'fname':''}}}},
      { id: 'Radio Htr', type: 'heater',parentNode:'Comm', position:{x:675, y:50}, data:{name:'Radio Htr',pwr:telemetryDictionary['Radiator Radio Htr State']}},
      { id: 'Spoc Htr', type: 'heater',parentNode:'Comm', position:{x:900, y:50}, data:{name:'Spoc Htr',pwr:telemetryDictionary['Radiator Spoc Htr State']}},
      { id: 'Hpa', type: 'exxbox',parentNode:'Comm', position:{x:50, y:425}, data:{name:'Hpa',oriHeight:'150px', exWidth:'500px',value:{"Pcu Temp Raw":{'dbData':telemetryDictionary['Hpa Pcu Temp Raw'],'fname':''},"Temp Raw":{'dbData':telemetryDictionary['Hpa Temp Raw'],'fname':''}}}},
      { id: 'HPA Htr', type: 'heater',parentNode:'Comm', position:{x:185, y:325}, data:{name:'Hpa Htr',pwr:telemetryDictionary['Hpa Htr State']}},
      { id: 'HGA', type: 'exxbox',parentNode:'Comm', position:{x:50, y:125}, data:{name:'HGA',oriHeight:'150px', exWidth:'475px',value:{"Temp Raw":{'dbData':telemetryDictionary['HGA Temp Raw'],'fname':''}}}},

      { id: 'Spoc', type: 'exxbox',parentNode:'CDH', position:{x:775, y:125}, data:{name:'Spoc', exWidth:'600px', value:{"Internal Temp Raw":{'dbData':telemetryDictionary['Spoc Int Temp Raw'],'fname':''},"Spare 15 Temp Raw":{'dbData':telemetryDictionary['Spare 15 Temp Raw'],'fname':''},"Spare 34 Temp Raw":{'dbData':telemetryDictionary['Spare 34 Temp Raw'],'fname':''}}}},
      { id: 'AviDeck', type: 'exxbox',parentNode:'CDH', position:{x:50, y:125}, data:{name:'Avi Deck', value:{"T1 Raw":{'dbData':telemetryDictionary['Avi Deck T1 Raw'],'fname':''},"T2 Raw":{'dbData':telemetryDictionary['Avi Deck T2 Raw'],'fname':''},"T3 Raw":{'dbData':telemetryDictionary['Avi Deck T3 Raw'],'fname':''},"T4 Raw":{'dbData':telemetryDictionary['Avi Deck T4 Raw'],'fname':''}}}},
      { id: 'AviDeckHtr1', type: 'heater',parentNode:'CDH', position:{x:550, y:125}, data:{name:'Avi Htr1',pwr:telemetryDictionary['Avi Deck Htr1 State']}},
      { id: 'AviDeckHtr2', type: 'heater',parentNode:'CDH', position:{x:550, y:225}, data:{name:'Avi Htr2',pwr:telemetryDictionary['Avi Deck Htr2 State']}},
      { id: 'AviDeckHtr3', type: 'heater',parentNode:'CDH', position:{x:550, y:325}, data:{name:'Avi Htr3',pwr:telemetryDictionary['Avi Deck Htr3 State']}},
      { id: 'AviDeckHtr4', type: 'heater',parentNode:'CDH', position:{x:550, y:425}, data:{name:'Avi Htr4',pwr:telemetryDictionary['Avi Deck Htr4 State']}},

      { id: 'BCR', type: 'exxbox',parentNode:'EPS', position:{x:100, y:200}, data:{name:'BCR', value:{"Bcr Temp Raw":{'dbData':telemetryDictionary['Bcr Temp Raw'],'fname':''}}}},
      { id: 'SolarArray', type: 'exxbox',parentNode:'EPS', position:{x:1300, y:200}, data:{name:'SolarArray', exWidth:'650px', value:{"Nx Temp Raw":{'dbData':telemetryDictionary['Solar Nx Temp Raw'],'fname':''},"Px Temp Raw":{'dbData':telemetryDictionary['Solar Px Temp Raw'],'fname':''}, "Hinge In Nx T Raw":{'dbData':telemetryDictionary['Hinge Inboard Nx T Raw'],'fname':''}, "Hinge In Px T Raw":{'dbData':telemetryDictionary['Hinge Inboard Px T Raw'],'fname':''}, "Hinge Out Nx T Raw":{'dbData':telemetryDictionary['Hinge Outboard Nx T Raw'],'fname':''}, "Hinge Out Px T Raw":{'dbData':telemetryDictionary['Hinge Outboard Px T Raw'],'fname':''}, "Hinge Root Nx T Raw":{'dbData':telemetryDictionary['Hinge Root Nx T Raw'],'fname':''}, "Hinge Root Px T Raw":{'dbData':telemetryDictionary['Hinge Root Px T Raw'],'fname':''}}}},
      { id: 'Outboard Htr', type: 'heater',parentNode:'EPS', position:{x:1500, y:100}, data:{name:'Outboard Htr',pwr:telemetryDictionary['Hinge Outboard Htr State']}},
      { id: 'Root Htr', type: 'heater',parentNode:'EPS', position:{x:1300, y:100}, data:{name:'Root Htr',pwr:telemetryDictionary['Hinge Root Htr State']}},
      { id: 'Battery', type: 'exxbox',parentNode:'EPS', position:{x:700, y:200}, data:{name:'Battery', value:{"Bim Temp Raw":{'dbData':telemetryDictionary['Bim Temp Raw'],'fname':''},"Batt A Temp":{'dbData':telemetryDictionary['Bim Battery A Temp'],'fname':''},"Batt B Temp":{'dbData':telemetryDictionary['Bim Battery B Temp'],'fname':''},"Batt C Temp":{'dbData':telemetryDictionary['Bim Battery C Temp'],'fname':''},"Batt D Temp":{'dbData':telemetryDictionary['Bim Battery D Temp'],'fname':''},"Batt E Temp":{'dbData':telemetryDictionary['Bim Battery E Temp'],'fname':''}}}},
 
      { id: 'Boom', type: 'exxbox',parentNode:'Payload', position:{x:700, y:500}, data:{name:'Boom',oriHeight:'150px', value:{"Temp Raw":{'dbData':telemetryDictionary['Boom Temp Raw'],'fname':''}}}},
      { id: 'EESA', type: 'exxbox',parentNode:'Payload', position:{x:700, y:200}, data:{name:'EESA',oriHeight:'150px', value:{"T1 Raw":{'dbData':telemetryDictionary['EESA T1 Raw'],'fname':''}, "T2 Raw":{'dbData':telemetryDictionary['EESA T2 Raw'],'fname':''}}}},
      { id: 'EESA Htr', type: 'heater',parentNode:'Payload', position:{x:835, y:100}, data:{name:'EESA Htr',pwr:telemetryDictionary['EESA Htr State']}},
      { id: 'EIDPU', type: 'exxbox',parentNode:'Payload', position:{x:100, y:200}, data:{name:'EIDPU',oriHeight:'150px', value:{"T1 Raw":{'dbData':telemetryDictionary['EIDPU T1 Raw'],'fname':''}, "T2 Raw":{'dbData':telemetryDictionary['EIDPU T1 Raw'],'fname':''}, "Tif Raw":{'dbData':telemetryDictionary['EIDPU Tif Raw'],'fname':''}}}},
      { id: 'EIDPU Htr', type: 'heater',parentNode:'Payload', position:{x:235, y:100}, data:{name:'EIDPU Htr',pwr:telemetryDictionary['EIDPU Htr State']}},
      { id: 'Cam', type: 'exxbox',parentNode:'Payload', position:{x:100, y:500}, data:{name:'Cam',oriHeight:'150px', value:{"Temp Raw":{'dbData':telemetryDictionary['Cam Temp Raw'],'fname':''}}}},

      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:150, y:-62}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg']}},

    ]} 

  useEffect(() => {
    const telemetryDictionary1 = createTelemetryDictionary();
    const newNodes = createNodes(telemetryDictionary1); // Function create nodes from telemetryDictionary
    setNodes(newNodes);
  },[dbData]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};
  
  const initialEdges = [
    {id: 'edge1', source: 'SolarArrsadfaay',sourceHandle: 'source-left', target: 'BCR',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow          
          edges={initialEdges}
          onInit={onInit}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true} 
          zoomOnDoubleClick={true}
          fitView={true}
          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;
