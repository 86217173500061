import React, { useState, useEffect } from 'react';
import { InnerData } from './utils';

interface Time3dFacadeProps {
  data: {
    name: string;
    value: InnerData;
    targetDate: string;
  };
}

const Time3dFacade: React.FC<Time3dFacadeProps> = ({ data }) => {
  const [timeRemaining, setTimeRemaining] = useState<string[]>(['00', '00', '00', '00']);
  const [color, setColor] = useState<string>('green');

  useEffect(() => {
    const timer = setInterval(() => {
      const [days, hours, minutes, seconds] = calculateTimeDifference(new Date(data.targetDate));
      setTimeRemaining([days, hours, minutes, seconds]);
      setColor(getColor(days, hours, minutes));
    }, 1000);

    return () => clearInterval(timer);
  }, [data.targetDate]);

  const calculateTimeDifference = (targetDate: Date): string[] => {
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    let days, hours, minutes, seconds;

    if (difference >= 0) {
      days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
      hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0');
    } else {
      const pastDifference = Math.abs(difference);
      days = '-' + String(Math.floor(pastDifference / (1000 * 60 * 60 * 24))).padStart(2, '0');
      hours = String(Math.floor((pastDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      minutes = String(Math.floor((pastDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      seconds = String(Math.floor((pastDifference % (1000 * 60)) / 1000)).padStart(2, '0');
    }

    return [days, hours, minutes, seconds];
  };

  const getColor = (days: string, hours: string, minutes: string): string => {
    const totalHours = parseInt(days) * 24 + parseInt(hours) + parseInt(minutes) / 60;

    if (totalHours <= 1) {
      return 'red';
    } else if (totalHours <= 12) {
      return 'orange';
    } else if (totalHours <= 24) {
      return 'yellow';
    } else {
      return 'green';
    }
  };

  const TimeUnit: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ fontSize: '1.3em' }}>{label}</div>
      <div style={{ fontSize: '3.7em', color }}>{value}</div>
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#000000',
        width: '950px',
        height: '240px'
      }}
    >
      <TimeUnit label="Days" value={timeRemaining[0]} />
      <div style={{
                display: 'flex',
                flexDirection: 'row',
      }}>
      <TimeUnit label="Hours" value={timeRemaining[1]} />
      <div style={{ fontSize: '3.7em', color, alignSelf: 'flex-end' }}>:</div>
      <TimeUnit label="Minutes" value={timeRemaining[2]} />
      <div style={{ fontSize: '3.7em', color, alignSelf: 'flex-end' }}>:</div>
      <TimeUnit label="Seconds" value={timeRemaining[3]} />
      </div>
      
    </div>
  );
};

export default Time3dFacade;
