export type InnerDataValue = {
  telemetry: string;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export type VisibleTooltips = { [key: string]: boolean };


export interface Dbdata{
  telemetry: string;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      className: string;
      id: string;
      backgroundImage: string;
      power: TLM;
      power1: TLM;
      powerName: string;
      power1Name: string;
      spocAV: TLM;
      spocBV: TLM;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export type LocationData = {
  Azimuth: number | null;
  Elevation: number | null;
};

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export const nameToMne = {

  /* Sensor */

  /* Bim */
  "Bim Battery A Temp":"tcs_spoc_bim_battery_a_t_agg",
  "Bim Battery B Temp":"tcs_spoc_bim_battery_b_t_agg",
  "Bim Battery C Temp":"tcs_spoc_bim_battery_c_t_agg",
  "Bim Battery D Temp":"tcs_spoc_bim_battery_d_t_agg",
  "Bim Battery E Temp":"tcs_spoc_bim_battery_e_t_agg",
  "Bim Temp Raw":"tcs_spoc_bim_t_raw_agg",
  "Bcr Temp Raw":"tcs_spoc_bcr_t_raw_agg",

  /* Solar Array */
  "Hinge Inboard Nx T Raw":"tcs_spoc_hinge_inboard_nx_t_raw_agg",
  "Hinge Inboard Px T Raw":"tcs_spoc_hinge_inboard_px_t_raw_agg",
  "Hinge Outboard Nx T Raw":"tcs_spoc_hinge_outboard_nx_t_raw_agg",
  "Hinge Outboard Px T Raw":"tcs_spoc_hinge_outboard_px_t_raw_agg",
  "Hinge Root Nx T Raw":"tcs_spoc_hinge_root_nx_t_agg",
  "Hinge Root Px T Raw":"tcs_spoc_hinge_root_px_t_agg",
  "Solar Nx Temp Raw":"tcs_spoc_solararray_nx_t_raw_agg",
  "Solar Px Temp Raw":"tcs_spoc_solararray_px_t_raw_agg",

  /* Avi Deck */
  "Avi Deck T1 Raw":"tcs_supervisor_avionics_deck_1_t_raw_agg",
  "Avi Deck T2 Raw":"tcs_supervisor_avionics_deck_2_t_raw_agg",
  "Avi Deck T3 Raw":"tcs_supervisor_avionics_deck_3_t_raw_agg",
  "Avi Deck T4 Raw":"tcs_supervisor_avionics_deck_4_t_raw_agg",

  /* Prop Deck */
  "Prop Deck T1 Raw":"tcs_spoc_prop_deck_1_t_agg",
  "Prop Deck T2 Raw":"tcs_spoc_prop_deck_2_t_agg",
  "Prop Deck T3 Raw":"tcs_spoc_prop_deck_3_t_agg",
  "Prop Deck T4 Raw":"tcs_spoc_prop_deck_4_t_agg",

  /* Payload */
  "Boom Temp Raw":"tcs_supervisor_boom_actuator_t_raw_agg",
  "EESA T1 Raw":"tcs_supervisor_eesa_1_t_raw_agg",
  "EESA T2 Raw":"tcs_supervisor_eesa_2_t_raw_agg",
  "EIDPU T1 Raw":"tcs_supervisor_eidpu_1_t_raw_agg",
  "EIDPU T2 Raw":"tcs_supervisor_eidpu_2_t_raw_agg",
  "EIDPU Tif Raw":"tcs_supervisor_eidpu_if_t_raw_agg",
  "Cam Temp Raw":"tcs_spoc_cptn_cam_t_raw_agg",

  /* Prop Fluidics */
  "Fluidics T1":"tcs_spoc_fluidics_1_t_agg",
  "Fluidics T2":"tcs_spoc_fluidics_2_t_agg",
  "Fluidics T3":"tcs_spoc_fluidics_3_t_agg",
  "Fluidics T4":"tcs_spoc_fluidics_4_t_agg",

  /* Comms */
  "Radiator Radio Temp Raw":"tcs_supervisor_radiator_radio_t_raw_agg",
  "Radiator Spoc Temp Raw":"tcs_supervisor_radiator_spoc_t_raw_agg",
  "Hpa Pcu Temp Raw":"tcs_supervisor_hpa_pcu_t_raw_agg",
  "Hpa Temp Raw":"tcs_supervisor_hpa_t_raw_agg",
  "HGA Temp Raw":"tcs_spoc_hga_t_raw_agg",
 
  /* Prop */
  "He Bottle T1 Raw":"tcs_supervisor_he_bottle_1_t_raw_agg",
  "He Bottle T2 Raw":"tcs_supervisor_he_bottle_2_t_raw_agg",
  "He Reg Temp Raw":"tcs_spoc_he_reg_t_raw_agg",
  "Fu T1 N Temp":"tcs_spoc_fu_tank_n_1_t_agg",
  "Fu T2 N Temp":"tcs_spoc_fu_tank_n_2_t_agg",
  "Fu T1 S Temp":"tcs_spoc_fu_tank_s_1_t_agg",
  "Fu T2 S Temp":"tcs_spoc_fu_tank_s_2_t_agg",
  "Fu T1 P Temp":"tcs_spoc_fu_tank_port_1_t_agg",
  "Fu T2 P Temp":"tcs_fu_tank_port_2_t_raw_agg",
  "Ox T1 N Temp":"tcs_spoc_ox_tank_n_1_t_agg",
  "Ox T2 N Temp":"tcs_spoc_ox_tank_n_2_t_agg",
  "Ox T1 S Temp":"tcs_spoc_ox_tank_s_1_t_agg",
  "Ox T2 S Temp":"tcs_spoc_ox_tank_s_2_t_agg",
  "Ox T1 P Temp":"tcs_spoc_ox_tank_port_1_t_agg",
  "Ox T2 P Temp":"tcs_ox_tank_port_2_t_raw_agg",
  "Fh Ox Temp":"tcs_spoc_fh1_ox_t_agg",
  "Fh Fu Temp":"tcs_spoc_fh2_fu_t_agg",
  "Eng Fu Temp":"tcs_spoc_eng_fu_t_agg",
  "Eng Ox Temp":"tcs_spoc_eng_ox_t_agg",
  "TvcX Temp":"tcs_spoc_tvc_x_t_agg",
  "TvcZ Temp":"tcs_spoc_tvc_z_t_agg",

  /* PIU */
  "Piu Temp":"tcs_spoc_piu_t_agg",

  /* RCS */
  "N2 B1 Temp Raw":"tcs_spoc_n2_bottle_1_t_raw_agg",
  "N2 B2 Temp Raw":"tcs_spoc_n2_bottle_2_t_raw_agg",
  "N2 B3 Temp Raw":"tcs_spoc_n2_bottle_3_t_raw_agg",
  "N2 B4 Temp Raw":"tcs_spoc_n2_bottle_4_t_raw_agg",
  "N2 TP1 Raw":"tcs_spoc_rtd_n2_bottle_probe_1_t_agg",
  "N2 TP2 Raw":"tcs_spoc_rtd_n2_bottle_probe_2_t_agg",
  "N2 TP3 Raw":"tcs_spoc_rtd_n2_bottle_probe_3_t_agg",
  "N2 TP4 Raw":"tcs_spoc_rtd_n2_bottle_probe_4_t_agg",
  "RCS A Temp Raw":"tcs_spoc_rcs_a_t_raw_agg",
  "RCS B Temp Raw":"tcs_spoc_rcs_b_t_raw_agg",

  /* FSS */
  "Fss A Temp":"tcs_spoc_rtd_fss_a_t_agg",
  "Fss B Temp":"tcs_spoc_rtd_fss_b_t_agg",

  /* Spoc */
  "Spoc Int Temp Raw":"tcs_spoc_spoc_internal_t_raw_agg",
  "Spare 15 Temp Raw":"tcs_spoc_15_spare_t_raw_agg",
  "Spare 34 Temp Raw":"tcs_spoc_34_spare_t_raw_agg",

  /* Star Trackers */
  "St A Temp Raw":"tcs_supervisor_st_a_t_raw_agg",
  "St B Temp Raw":"tcs_spoc_st_b_t_raw_agg",

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",
  "Vehicle Mode Sh":"state_spinand0_last_cmd_vehicle_mode_sh", /* */

  /* Heaters */

  /* Avi Deck Heater */
  "Avi Deck Htr1 State":"tcs_thermostats_avionics_deck_1_htr_state_agg",
  "Avi Deck Htr2 State":"tcs_thermostats_avionics_deck_2_htr_state_agg",
  "Avi Deck Htr3 State":"tcs_thermostats_avionics_deck_3_htr_state_agg",
  "Avi Deck Htr4 State":"tcs_thermostats_avionics_deck_4_htr_state_agg",

  /* Prop Deck Heater */
  "Prop Deck Htr1 State":"tcs_thermostats_prop_deck_1_htr_state_agg",
  "Prop Deck Htr2 State":"tcs_thermostats_prop_deck_2_htr_state_agg",
  "Prop Deck Htr3 State":"tcs_thermostats_prop_deck_3_htr_state_agg",
  "Prop Deck Htr4 State":"tcs_thermostats_prop_deck_4_htr_state_agg",

  /* Solar */
  "Hinge Outboard Htr State":"tcs_thermostats_hinge_outboard_htr_state_agg",
  "Hinge Root Htr State":"tcs_thermostats_hinge_root_htr_state_agg",

  /* Payload */
  "EESA Htr State":"tcs_thermostats_eesa_htr_state_agg",
  "EIDPU Htr State":"tcs_thermostats_eidpu_htr_state_agg",

  /* Comms */
  "Radiator Radio Htr State":"tcs_thermostats_radiator_radio_htr_state_agg",
  "Radiator Spoc Htr State":"tcs_thermostats_radiator_spoc_htr_state_agg",
  "Hpa Htr State":"tcs_thermostats_hpa_htr_state_agg",

  /* Prop */
  "He Bottle1 Htr State":"tcs_thermostats_he_bottle_1_htr_state_agg",
  "He Bottle2 Htr State":"tcs_thermostats_he_bottle_2_htr_state_agg",
  "Fu T1 N Htr State":"tcs_thermostats_fu_tank_n_1_htr_state_agg",
  "Fu T2 N Htr State":"tcs_thermostats_fu_tank_n_2_htr_state_agg",
  "Fu T1 S Htr State":"tcs_thermostats_fu_tank_s_1_htr_state_agg",
  "Fu T2 S Htr State":"tcs_thermostats_fu_tank_s_2_htr_state_agg",
  "Ox T1 N Htr State":"tcs_thermostats_ox_tank_n_1_htr_state_agg",
  "Ox T2 N Htr State":"tcs_thermostats_ox_tank_n_2_htr_state_agg",
  "Ox T1 S Htr State":"tcs_thermostats_ox_tank_s_1_htr_state_agg",
  "Ox T2 S Htr State":"tcs_thermostats_ox_tank_s_2_htr_state_agg",
  "Fh Ox Htr State":"tcs_thermostats_fh_1_ox_htr_state_agg",
  "Fh Fu Htr State":"tcs_thermostats_fh_2_fu_htr_state_agg",
  "Eng Fu Htr State":"tcs_thermostats_eng_fu_htr_state_agg",
  "Eng Ox Htr State":"tcs_thermostats_eng_ox_htr_state_agg",
  "Tvc Htr State":"tcs_thermostats_tvc_htr_state_agg",

  /* RCS */
  "N2 Htr State":"tcs_thermostats_n2_bottles_htr_state_agg",
  "RCS A Htr State":"tcs_thermostats_rcs_a_htr_state_agg",
  "RCS B Htr State":"tcs_thermostats_rcs_b_htr_state_agg",

}

export const checkOutOfLimits = (data: InnerData) => {
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData.limit) {
      const limitClass = determineColorNew(innerData.dbData.limit);
      if (limitClass === 'red-limit') {
        return `rgb(246, 80, 80)`;
      }
      else if (limitClass === 'yellow-limit') {
        return `rgb(230, 230, 65)`; 
      }
    }
  });
  return `green`;
};
