import { createContext, useContext } from 'react';
import { DateTime } from '@grafana/data';

interface TimeRange {
  from: DateTime;
  to: DateTime;
}

interface GUIValues {
  offSetX: number;
  startDateTime: string;
  endDateTime: string;
  aggTime: string;
  aggFn: { fn: string };
  screenSpacePanning: boolean;
  Agg: boolean;
  Last: boolean;
  lastCount: number;
}

interface ContextProps {
  timeRange: TimeRange;
  guiValues: GUIValues;
  oneWayLightTimeFieldValues: number;
}

const defaultContext: ContextProps = {
  timeRange: { from: new Date(), to: new Date() },
  guiValues: {
    offSetX: 0,
    startDateTime: '',
    endDateTime: '',
    aggTime: '1s',
    aggFn: { fn: 'last' },
    screenSpacePanning: false,
    Agg: false,
    Last: false,
    lastCount: 50,
  },
};

export const TimeRangeContext = createContext<ContextProps>(defaultContext);

export const useTimeRangeContext = () => useContext(TimeRangeContext);
