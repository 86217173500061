import { css, cx } from '@emotion/css';
import { TLM, VisibleTooltips, determineColorNew } from 'components/utils';
import React, { useState } from 'react'
import { Handle, Position } from 'reactflow';
import * as scheme from '../components/Scheme'; // Import your styl

interface SpocProp {
  data: {
    pripwr: TLM;
    secpwr: TLM;
  }

}

const Spoc: React.FC<SpocProp> = ({data}) => {

  const spocContainer = css`
  width: 1150px;
  height: 850px;
  background-color: rgb(22, 65, 108);
  border: 5px solid black;
`;

const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

const handleMneNameClick = (key: string) => {
  setVisibleTooltips(prevVisibleTooltips => ({
    ...prevVisibleTooltips,
    [key]: !prevVisibleTooltips[key]
  }));

  setTimeout(() => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: false
    }));
  }, 2000);
};

const getColor = (self: string, other: string) => {
  const selfN = parseFloat(self);
  const otherN = parseFloat(other);
  if (selfN > otherN) {
      return "rgb(72, 200, 44)";
  }
  else{
    return "rgb(100,100,100)";
  }
}

const [showPowerInfo, setShowPowerInfo] = useState(false);
const [showPowerInfo1, setShowPowerInfo1] = useState(false);

const handlePowerEllipseClick = () => {
  setShowPowerInfo(!showPowerInfo);

  setTimeout(() => {setShowPowerInfo(false)}, 2000);
};

const handlePowerEllipseClick1 = () => {
  setShowPowerInfo1(!showPowerInfo1);

  setTimeout(() => {setShowPowerInfo1(false)}, 2000);
};


  return (
    <div className={spocContainer}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:200}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:950}}/>
      <Handle type="target" position={Position.Right} id="target-right-2" style={{opacity:0, top:191}}/>
      <Handle type="target" position={Position.Right} id="target-right-3" style={{opacity:0, top:550}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:800}}/>
      <Handle type="source" position={Position.Right} id="source-right-2" style={{opacity:0, top:400}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p>Spoc</p>

      {data.pripwr && <div className={css`position: relative;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick}>
          <g>
            <g>
              <ellipse cx="30" cy="30" rx="25" ry="25" fill="rgb(38,38,38)" stroke={getColor(data.pripwr.telemetry, data.secpwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="30" y="45" fill={getColor(data.pripwr.telemetry, data.secpwr.telemetry)} fontFamily="Times New Roman" fontSize="50px" textAnchor="middle">A
              </text>
            </g>
          </g>
        </svg>
            {showPowerInfo && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("PriPower")}>
                  Pri
                  <span className={scheme.svgFnameTooltip}>Pri Power</span>
                  {visibleTooltips['PriPower'] && <span className={scheme.mnameTooltip}>{data.pripwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.pripwr.live}`,
                  scheme.classMap[determineColorNew(data.pripwr.limit)]
                )}
              >
                {data.pripwr.telemetry}
              </span>
              </div>
            </div>}
            </div>}

            {data.secpwr && <div className={css`position: relative;
            right: 0;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick1}>
          <g>
            <g>
              <ellipse cx="30" cy="30" rx="25" ry="25" fill="rgb(38,38,38)" stroke={getColor(data.secpwr.telemetry, data.pripwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="30" y="45" fill={getColor(data.secpwr.telemetry, data.pripwr.telemetry)} fontFamily="Times New Roman" fontSize="50px" textAnchor="middle">B
              </text>
            </g>
          </g>
        </svg>
            {showPowerInfo1 && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("SecPower")}>
                  Sec
                  <span className={scheme.svgFnameTooltip}>Sec Power</span>
                  {visibleTooltips['SecPower'] && <span className={scheme.mnameTooltip}>{data.secpwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.secpwr.live}`,
                  scheme.classMap[determineColorNew(data.secpwr.limit)]
                )}
              >
                {data.secpwr.telemetry}
              </span>
              </div>
            </div>}
            </div>}
    </div>
  )
}

export default Spoc;
